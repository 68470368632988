@import "./resetCSS.css";


body {
	background-color: #fff1b7;
}

body span {
	font-family: "Poppins", sans-serif !important;
}

.pageLayout {
	margin-top: 32px;
	flex-direction: column;
	padding: 15px;
	display: block;
}

.introText {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
}

.formContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
}

.bodyElement>* {
	padding: 10px, 10px, 10px, 10px;
	flex-direction: column;
}

.label {
	color: #000000;
}

.tooltip {
	width: 100%;
}

.inputField {
	background-color: #ffffff !important;
	border-color: #b2b2b2 !important;
}

.button {
	background-color: #000000;
	border-color: #b2b2b2;
	height: 15px;
}

.buttonLabel {
	background-color: #000000;
	color: #afb4b7;
}

.topBar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	overflow: hidden;
	background-color: #fffffd;
}

.topBarElement {
	float: left;
	color: #324234;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
}

form.loading {
	filter: blur(1.5px);
}

form.loading input,
form.loading textarea,
form.loading select,
form.loading button {
	pointer-events: none;
}

.poppins-thin {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: italic;
}